import React from "react";
import { Link } from "react-router-dom";

const ProjectDocumentsCard = ({id, projectName, title, seoScore, status, date, documentBrief, noCard, projectId }) => {
    return (
        <div className="cnt-2-div-1">
            {noCard ? (
                <div className="no-card-message">
                    <h5>No Document Available</h5>
                </div>
            ) : (
                <>
                    <div className="cnt-2-1">
                    {projectName && <Link to={`/project/${projectId}`} > {projectName} </Link>}
                    </div>
                    <div className="cnt-2-2">
                        {title && <Link to={`/document/${id}`} > {title} </Link>}
                    </div>
                    <div className="cnt-2-3">
                        {documentBrief !== '' ? `${documentBrief}` : "No description available"}
                    </div>
                    <div className="cnt-2-4">
                        SEO Score
                    </div>
                    <div className="cnt-2-5">
                        {seoScore !== null ? `${seoScore}%` : '- %'}
                    </div>
                    <div className="cnt-2-6">
                        <div className="">
                            {status && (
                                <span className={`status ${status.toLowerCase().replace(" ", "-")}`}>
                                    {status}
                                </span>
                            )}
                        </div>
                        <div className="cnt-2-3">
                            {date && date}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ProjectDocumentsCard;
