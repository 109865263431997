import chevron from '../../assets/Chevron_Down.svg'

const ProjectDetailContent = () => {

    return (
        <div style={{width: '70%'}}>
            <div className="rightContent">
                <div style={{fontWeight: 800, fontSize: 'large', marginBottom: '20px'}}>
                    AI recommendation for you
                </div>
            </div>

            <div className="cards">
                <div className="card-1">
                    <div>Main keyword</div>
                    <div className="article-1">This is the article title</div>
                    <div className="content-1">
                        <div className="content-div-1">Create with AI</div>
                        <div className="content-div-2">
                            Start from scratch
                            <img
                                style={{
                                    padding: '5px',
                                    backgroundColor: 'white',
                                    borderRadius: '15px'
                                }}
                                src={chevron}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}
export default ProjectDetailContent