import create from 'zustand';
import { persist } from 'zustand/middleware';
const safeLocalStorage = () => {
    try {
        return localStorage;
    } catch (error) {
        console.error('Error accessing localStorage:', error);
        return null;
    }
};

const useStore = create(
    persist(
        (set, get) => ({
            storeProjects: [],
            DrawerOpener: false,

            setProjects: (newProjects) => set(() => ({
                storeProjects: newProjects
            })),
            clearProjects: () => set({ storeProjects: [] }),

            storeDocuments: [],
            setDocuments: (newDocuments) => set(() => ({
                storeDocuments: newDocuments
            })),
            clearDocuments: () => set({ storeDocuments: [] }),
            updateStoreProject: (id, updatedName) => {
                const { storeProjects } = get();
                const projectId = Number(id);
                const updatedProjects = storeProjects.map((project) =>
                    project.id === projectId ? { ...project, name: updatedName } : project
                );

                set({ storeProjects: updatedProjects });

            },
            setDrawerOpener: (DrawerOpener) => set(() => ({
                DrawerOpener: DrawerOpener
            })),
            clearDrawerOpener: () => set({ DrawerOpener: false }),

        }),
        {
            name: 'projects-storage',
            getStorage: () => safeLocalStorage(),
        }
    )
);

export default useStore;
