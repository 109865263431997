import React, { useState } from "react";
import Cookies from "js-cookie";
import { Input, Modal, Select, Typography } from "antd";
import api from "../../utils/api";
import { toast } from "react-toastify";
import { Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import fetchProjects from "../../utils/fetchProjects";
import useStore from "../../Store"

const locations = JSON.parse(process.env.REACT_APP_LOCATION_CONFIG || "[]");

const CreateProjectModal = ({ open, setIsModalOpen, isDashboard, setScreen1, setScreen2 }) => {
    const [name, setName] = useState("");
    const navigate = useNavigate();
    const [url, setUrl] = useState("");
    const [location, setLocation] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [buttonColor, setButtonColor] = useState('grey');
    const { setProjects, clearProjects } = useStore();
    const token = Cookies.get("access_token");
    const { Option } = Select;
    const { Text } = Typography;

    const handleOk = async () => {
        try {
            if (!token) {
                throw new Error("No access token found");
            }

            const response = await api.post(
                "/projects/",
                { name, url, location },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const projectId = response.data.project.id;
            toast.success("Project created successfully");
            handleCancel();
            const projects = await fetchProjects();
            setName('');
            clearProjects();
            setProjects(projects);
            navigate(`/project/${projectId}`);
        } catch (err) {
            console.error("Project creation failed:", err);
            toast.error("Error creating project. Please try again.");
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setName('');
        setUrl('');
        setLocation('');
        setIsButtonDisabled(true);
        setButtonColor('grey');
    };

    const isValidURL = (url) => {
        const regex = /^https?:\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:\/?#@!$&'()*+,;=]*)?$/;
        return regex.test(url);
    };

    const handleNameChange = (e) => {
        const newName = e.target.value;
        setName(newName);
        validateForm(newName, url, location);
    };

    const handleUrlChange = (e) => {
        const newUrl = e.target.value;
        setUrl(newUrl);
        if (name && isValidURL(newUrl) && location) {
            console.log(isValidURL(newUrl));
            setIsButtonDisabled(false);
            setButtonColor('black');
        } else {
            setIsButtonDisabled(true);
            setButtonColor('grey');
        }
    };

    const validateForm = (name, url, location) => {
        if (name && isValidURL(url) && location) {
            setIsButtonDisabled(false);
            setButtonColor("black");
        } else {
            setIsButtonDisabled(true);
            setButtonColor("grey");
        }
    };

    return (
        <div>
            <Modal
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
                width={700}
                className="padding-modal"
                footer={[
                    <div className="padding-modal" key="footer">
                        <Button
                            key="submit"
                            type="primary"
                            onClick={handleOk}
                            disabled={isButtonDisabled}
                            style={{ backgroundColor: buttonColor, borderColor: buttonColor, color: 'white' }}
                        >
                            Create Project
                        </Button>
                    </div>,
                ]}
            >
                <div className="padding-modal">
                    <Typography.Title level={2}>Create a new project</Typography.Title>
                    <Text strong>*Name</Text>
                    <div>
                        <Input
                            placeholder="Project Name"
                            value={name}
                            onChange={handleNameChange}
                            type="text"
                            required
                            style={{ width: 400, marginTop: 5, marginBottom: 15 }}
                        />
                    </div>
                    <Text strong level={5}>*URL</Text>
                    <div>
                        <Input
                            placeholder="URL"
                            value={url}
                            onChange={handleUrlChange}
                            type="text"
                            required
                            style={{ width: 400, marginTop: 5 }}
                        />
                    </div>
                    {url && !isValidURL(url) && <> <em className="hint">The link must contain "http://" or "https://".
                    </em> <br />    </>}
                    <Select
                        showSearch
                        style={{ width: 200, marginTop: 15 }}
                        placeholder="Select location"
                        value={location || undefined}
                        onChange={(value) => {
                            setLocation(value);
                            validateForm(name, url, value);
                        }}
                    >
                        {locations.map((country) => (
                            <Option key={country} value={country}>
                                {country}
                            </Option>
                        ))}
                    </Select>
                </div>
            </Modal>
        </div>
    );
};

export default CreateProjectModal