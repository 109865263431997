import React, { useState, useRef } from 'react';
import copyIcon from '../../assets/fi_8860773.svg';
import addIcon from '../../assets/Add.svg';
import rectangleIcon from '../../assets/Rectangle 2636.svg';
import avgScoreIcon from '../../assets/Avg score.svg';
import initialIcon from '../../assets/initial.svg';
import headlineIcon from '../../assets/fi_4662543.svg';
import subheadingIcon from '../../assets/fi_4662543 (1).svg';
import introIcon from '../../assets/List_Add.svg';
import faqIcon from '../../assets/fi_3524344.svg';
import keyPointsIcon from '../../assets/List_Checklist.svg';
import chevronLeft from '../../assets/Chevron_left.svg';
import dragVertical from '../../assets/Drag_Vertival.svg';
import imageIcon from '../../assets/fi_9261193.svg';
import summarizeIcon from '../../assets/List_Check.svg';
import "../Documents/documentDetail.css";
import KeywordsListCard from './KeywordsListCard';
import KeywordModal from './KeywordModal';
import SerpCard from './SerpCard';
import SerpModal from './SerpModal';

const DocumentDetailContent = () => {
    const [title, setTitle] = useState('');
    const [display, setDisplay] = useState(true);
    const [openSerpModal, setOpenSerpModal] = useState(false);
    const [description, setDescription] = useState('');
    const [activeTab, setActiveTab] = useState('Element');
    const [activeContent, setActiveContent] = useState('Element');
    const [titles, setTitles] = useState([
        { id: 1, text: "Contact Us", count: 92 },
        { id: 2, text: "About Us", count: 78 },
        { id: 3, text: "Birthday", count: 33 },
    ]);

    const titleBoxRef = useRef(null);

    const handleTitle = (e) => setTitle(e.target.value);
    const handleDescription = (e) => setDescription(e.target.value);
    const handleDisplay = () => setDisplay(!display);
    const [addKeyword, setAddKeyword] = useState(false);

    const handleDragStart = (title) => (e) => {

        e.dataTransfer.setData("text/plain", JSON.stringify({ text: title.text, id: title.id }));
    };

    const handleDrop = (e) => {
        e.preventDefault();

        const data = JSON.parse(e.dataTransfer.getData("text/plain"));
        const oldValue = title;
        const count = oldValue.length;
        const id = data.id;

        const titleBox = titleBoxRef.current;
        const { top, left, right, bottom } = titleBox.getBoundingClientRect();

        const { clientX, clientY } = e;
        if (clientX >= left && clientX <= right && clientY >= top && clientY <= bottom) {
            setTitle(data.text);

            setTitles((prevTitles) => {
                const updatedTitles = prevTitles.filter((t) => t.text !== data.text);

                if (oldValue && oldValue.trim() !== '') {
                    updatedTitles.push({ id: id, text: oldValue, count: count });
                }

                return updatedTitles;
            });
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };


    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setActiveContent(tab)
        if (tab === "Element") {
            setDisplay(true)
        }
    };

    const handleSerpModal =()=>{
        setOpenSerpModal(true)
    }

    const [documentKeywords, setDocumentKeywords] = useState([]);

    const handleKeywordSelect = (name) => {
        setDocumentKeywords((prevSelected) => {
            if (prevSelected.includes(name)) {
                // If the keyword is already selected, remove it
                return prevSelected.filter((keyword) => keyword !== name);
            } else {
                // Otherwise, add the selected keyword
                return [...prevSelected, name];
            }
        });
    };

    const KeywordDemoList = [
        { label: "breevy", value: "breevy", kd: 1, volume: 1.2 },
        { label: "breevy-test", value: "breevy-test", kd: 33, volume: 1.5 },
        { label: "breevy-ai", value: "breevy-ai", kd: 44, volume: 2.9 }
    ];

    const SerpDemo = [
        { id: 1, label: "demo1", url: "https://www.figma.com/design/dRwTctDMTVwas8vF26EGxl/Breevy---MVP-(Copy)-(Copy)?node-id=2049-65134&node-type=frame&t=mTC72MXuRiMBZ2Q4-0" },
        { id: 2, label: "demo2", url: "https://www.web.whatsapp.com" },
        { id: 3, label: "demo3", url: "https://www.google.com" },
        { id: 4, label: "demo1", url: "https://www.figma.com/design/dRwTctDMTVwas8vF26EGxl/Breevy---MVP-(Copy)-(Copy)?node-id=2049-65134&node-type=frame&t=mTC72MXuRiMBZ2Q4-0" },
        { id: 5, label: "demo2", url: "https://www.web.whatsapp.com" },

    ];

    const handleOpen = () => {
        setAddKeyword(true)
    }

    return (
        <>
            <div className="Container">
                <div className="left" onDrop={handleDrop} onDragOver={handleDragOver}>
                    <div className="left-pading">
                        <div className="copyImg">
                            <img className="imgCopy" src={copyIcon} alt="Copy" />
                        </div>
                        <div className="flexMeta">
                            <img className="Imageadd" src={addIcon} alt="Add" />
                            <div className="metaText">CREATE META DESCRIPTION</div>
                        </div>
                        <div ref={titleBoxRef} className={`titleBox ${title ? 'no-border' : 'border'}`}>
                            <input
                                className="titleInput"
                                type="text"
                                placeholder="TITLE"
                                onChange={handleTitle}
                                value={title}
                            />
                        </div>
                        <div className={`textBox ${description ? 'no-border' : 'border'}`}>
                            <input
                                className="textInput"
                                type="text"
                                placeholder="START WRITING"
                                value={description}
                                onChange={handleDescription}
                            />
                        </div>
                    </div>
                    <footer className="Fotter">
                        <div className="flexFotter">
                            <div className="button">Write more</div>
                            <div className="words">{description.length || 0} words</div>
                        </div>
                    </footer>
                </div>
                <div className="right">
                    <div className="pdng">
                        <div className="score">SEO score</div>
                        <div className="meter">
                            <div className="rectangle">
                                <img src={rectangleIcon} alt="" />
                                <div className="avgScore">
                                    <img src={avgScoreIcon} alt="" />
                                </div>
                            </div>
                            <div className="perc">17%</div>
                        </div>
                    </div>
                    <div className="scoring">
                        <div className="brdr">
                            <div className="keyword-class">keywords</div>
                            <div className="flexScore">
                                <div className="detail-page-keyword">0%</div>
                                <img src={initialIcon} alt="" />
                            </div>
                        </div>
                        <div className="brdr">
                            <div className="keyword-class">Readability</div>
                            <div className="flexScore">
                                <div className="detail-page-keyword">0</div>
                                <img src={initialIcon} alt="" />
                            </div>
                        </div>
                        <div className="brdr-1">
                            <div className="keyword-class">Originality</div>
                            <div className="flexScore">
                                <div className="detail-page-keyword">0</div>
                                <img src={initialIcon} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="mid">
                        <div className="midFlex">
                            {['Element', 'Assistance', 'Keyword', 'SERP'].map((tab) => (
                                <div
                                    key={tab}
                                    className={`${activeTab === tab ? 'Active' : 'div-2-builder'}`}
                                    onClick={() => handleTabChange(tab)}
                                >
                                    {tab}
                                </div>
                            ))}
                        </div>
                    </div>
                    {display && activeContent === "Element" && (
                        <div>
                            <div className="typoflex">
                                <div className="typodiv-1">
                                    <div className="typoContent" onClick={handleDisplay}>
                                        <img className="typoImg" src={headlineIcon} alt="" />
                                        <div>Headline</div>
                                    </div>
                                </div>
                                <div className="typodiv-1">
                                    <div className="typoContent">
                                        <img className="typoImgsub" src={subheadingIcon} alt="" />
                                        <div>Subheading</div>
                                    </div>
                                </div>
                                <div className="typodiv-1">
                                    <div className="typoContent">
                                        <img className="typoImgAdd" src={introIcon} alt="" />
                                        <div>Introduction</div>
                                    </div>
                                </div>
                            </div>

                            <div className="typoflex">
                                <div className="typodiv-1">
                                    <div className="typoContent">
                                        <img className="typoImg" src={faqIcon} alt="" />
                                        <div>FQA</div>
                                    </div>
                                </div>
                                <div className="typodiv-1">
                                    <div className="typoContent">
                                        <img className="typoImg" src={keyPointsIcon} alt="" />
                                        <div>Key points</div>
                                    </div>
                                </div>
                                <div className="typodiv-1">
                                    <div className="typoContent">
                                        <img className="typoImgAdd" src={imageIcon} alt="" />
                                        <div>Image</div>
                                    </div>
                                </div>
                            </div>
                            <div className="typoflex">
                                <div className="typodiv-1">
                                    <div className="typoContent">
                                        <img className="typoImg" src={summarizeIcon} alt="" />
                                        <div>Summarize</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {!display && activeContent === "Element" && (
                        <>
                            <div className="createTitle">
                                <div className="createTitleFlex">
                                    <div className="createArrow">
                                        <img src={chevronLeft} alt="Back" onClick={handleDisplay} />
                                    </div>
                                    <div className="createTitleText">Create Title</div>
                                </div>
                                <div className="insertText">+INSERT ALL</div>
                            </div>
                            <div>
                                {titles.map((title) => (
                                    <div
                                        key={title.id}
                                        className="titlePadding"
                                        onDragStart={handleDragStart(title)}
                                        draggable
                                    >
                                        <div className="titleBorder">
                                            <div className="titleImageFlex">
                                                <img className="titleImage" src={dragVertical} alt="Drag" />
                                                <div className="titleParagraph">
                                                    {title.text.split('\n').map((line, index) => (
                                                        <React.Fragment key={index}>{line}<br /></React.Fragment>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="titleCount">{title.count}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                    {activeContent === "Keyword" && (
                        <div>
                            <div className="addKeywordText" onClick={handleOpen}>+ ADD KEYWORD</div>
                            <div className="hidingsOfaddKeyword">
                                <div className="hiding1Keyword">Keyword</div>
                                <div className="hiding2KD">KD</div>
                                <div className="hiding3Volume">Volume</div>
                            </div>

                            {KeywordDemoList.map((keyword, index) => (
                                <KeywordsListCard
                                key={index}
                                name={keyword.label}
                                kd={keyword.kd}
                                vol={keyword.volume}
                                onSelect={handleKeywordSelect} 
                                isSelected={documentKeywords.includes(keyword.label)}
                            />
                            ))}
                        </div>
                    )}

                    {activeContent === "SERP" && (
                        <>
                            <div className="serpHeightFix">
                                {SerpDemo.map((item) => (
                                    <SerpCard key={item.id} id={item.id} title={item.label} url={item.url} />
                                ))}
                            </div>
                            <div class="viewAllbtnpdng" onClick={handleSerpModal}>
                                <div class="viewAllbtn">
                                    View all
                                </div>
                            </div>
                        </>
                    )}



                </div>
            </div>

            <KeywordModal openKeword={addKeyword} setOpenKeyword={setAddKeyword} keywordOptions={KeywordDemoList} documentKeywords={documentKeywords} setDocumentKeywords={setDocumentKeywords} isBuilder={true} />
           <SerpModal open={openSerpModal} serpList={SerpDemo} setOpenSerpModal={setOpenSerpModal}/>
        </>
    );
}

export default DocumentDetailContent;
