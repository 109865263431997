import React, { useEffect, useState } from "react";
import DocumentNav from "./DocumentNav";
import { useParams } from "react-router-dom";
import api from "../../utils/api";
import DocumentDetailContent from "./DocumentDetailContent";

const DocumentDetail = () => {
    const [doc, setDoc] = useState([])
    let { id } = useParams();
    useEffect(() => {
        const getDocument = async () => {
            try {
                const response = await api.get(
                    `/document-details/${id}/`,
                );
                setDoc(response.data)
            } catch (err) {
                console.log("Error Edited project. Please try again.");
            }
        }

        getDocument();
    }, []);

    return (
        <>
            <DocumentNav name={doc.name} id={doc.id} projectId={doc.project} />
            <DocumentDetailContent  />            
        </>
    )
};

export default DocumentDetail;
