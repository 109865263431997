import React, { useState } from "react";
import { Input } from "antd";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";  // Keep using the standard axios import as in your original code    
import BackArrow from "../../../assets/backarrow.png";
import { toast } from "react-toastify";
import Closedeyeimg from "../../../assets/closed_Eye.png";
import Openeyeimg from "../../../assets/open_Eye.png";
import Cookies from "js-cookie";
import useStore from '../../../Store';
import {emailValidate, isValidPassword} from "../../../utils/utils";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { setProjects } = useStore();

    const handleLogin = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.post("/api/login/", {
                email,
                password,
            });
            Cookies.set("access_token", response.data.access_token);
            toast.success("Login successful");
            const projects = response.data?.user?.projects;
            if (projects && projects.length > 0) {
                setProjects(projects)
            }
            navigate("/dashboard");
        } catch (err) {
            console.error("Login failed:", err);
            toast.error("Login failed. Please check your credentials and try again.");
        } finally {
            setLoading(false);
        }
    };
    const isValidEmail = emailValidate(email)
    const isFormValid = isValidEmail && isValidPassword(password);

    return (
        <div className="login-main-content">
            <Link to="/login">
                <div className="backarrow">
                    <img src={BackArrow} alt="Back" />
                </div>
            </Link>
            <h1 className="login_text">Login</h1>
            {error && <div className="error-message">{error}</div>}
            <Input
                name="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                required
            />
            <div className="margin-top">
                <Input.Password
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    required
                    iconRender={(visible) =>
                        visible ? (
                            <img
                                style={{ cursor: "pointer" }}
                                src={Openeyeimg}
                                alt="closed"
                            />
                        ) : (
                            <img
                                style={{ cursor: "pointer" }}
                                src={Closedeyeimg}
                                alt="closed"
                            />
                        )
                    }
                />
            </div>
            <div className="forgotpass">

                <Link className="color_blue2" to="/forgot-password">
                    Forgot password?
                </Link>

            </div>
            <div className="margin-top">
                <button
                    onClick={handleLogin}
                    disabled={!isFormValid || loading}
                    className="createaccountbtn"
                    style={{
                        backgroundColor: isFormValid ? "#262626" : "grey",
                        color: "white",
                        cursor: isFormValid ? "pointer" : "not-allowed",
                    }}
                >
                    {loading ? "Logging in..." : "Login"}
                </button>
            </div>
        </div>
    );
};

export default Login;