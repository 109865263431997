import vector from "../../assets/vector.svg";
import React, { useEffect, useState } from "react";
import left from "../../assets/Chevron_left.svg";
import downWhite from "../../assets/Chevron_Down_White.svg";
import edit from "../../assets/fi_9356210.svg";
import "../Documents/documentDetail.css"
import DocEdit from "./DocEdit";
import { Link } from "react-router-dom";
import TargetAudience from "../TargetAudience";
import SaveModal from "./SaveModal";
import api from "../../utils/api";

const DocumentNav = ({ name, id, projectId }) => {
    const [docEditModal, setDocEditModal] = useState(false);
    const [docName, setDocName] = useState(name)
    const [openSaveModal, setOpenSaveModal] = useState(false)
    const [open, setOpen] = useState(false)
    const [tone, setTone] = useState([])
    const handleEdit = (item) => {

        setDocEditModal(true)
    }
    useEffect(() => {
        const getTargetAudience = async () => {
            try {

                const response = await api.get(
                    "/tones/",

                );

                setTone(response.data)
                console.log("Project Edited successfully");



            } catch (err) {
                console.error("Error Edited project. Please try again.");
            }
        };


        getTargetAudience();
    }, []);

    const handleOpenTone = () => {
        if (open) {
            setOpen(false)
        } else {
            setOpen(true)
        }
    }

    const saveModal = () => {
        setOpenSaveModal(true)
    }
    useEffect(() => {
        setDocName(name)
    }, [name])

    return (
        <>
            <div className="navBar">
                <div className="navLeft">
                    <div className="backArrow">
                        <Link to={`/project/${projectId}/documents`}> <img src={left} alt="Back" /> </Link>
                    </div>
                    <div className="newDocText">
                        {docName}
                    </div>
                    <div className="editButton">
                        <img src={edit} alt="Edit" onClick={handleEdit} />
                    </div>
                </div>
                <div className="navRight">
                    <TargetAudience />
                    <div className="toneFlex" onClick={handleOpenTone}>
                        <div className="toneText">
                            Tone of voice
                        </div>
                        <img className="div-2-img-1" src={vector} alt="" />
                        {open &&
                            <div className="tone-dropdown">
                                <div className="dropdown-div1">
                                    <div className="dropdown-h1">Tone of Voice</div>
                                    <div className="dropdown-p1">
                                        Defining the correct tone of voice enhances AI's ability to <br />
                                        generate superior content.
                                    </div>
                                </div>

                                {tone.map(({ id, tone_value }) => (
                                    <div key={id}>
                                        <div className="dropdown-div1">
                                            <div className="dropdown-h2">{tone_value}</div>

                                        </div>
                                        <div className="dropdown-p2">

                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                    <div className="saveFlex" onClick={saveModal}>
                        <div className="saveText" >
                            Save
                        </div>
                        <img className="saveDownImg" src={downWhite} alt="Dropdown" />
                    </div>
                </div>
            </div>

            <DocEdit open={docEditModal} setIsModalOpen={setDocEditModal} id={id} docName={docName} setDocName={setDocName} />
            <SaveModal open={openSaveModal} setOpenSaveModal={setOpenSaveModal} docName={docName} setDocName={setDocName} id={id} />
        </>
    );
};

export default DocumentNav;
