const KeywordsListCard = ({ name, kd, vol, isSelected, onSelect }) => {

    return (
        <>
            <div className="addKeywordsRow">
            <div className="keyWordFleximg">
                <div className="custom-checkbox">
                    <input
                        type="checkbox"
                        id={`checkbox-${name}`}
                        checked={isSelected}
                        onChange={() => onSelect(name)} // Trigger the selection handler
                    />
                    <label htmlFor={`checkbox-${name}`}></label>
                </div>
                <div className="addKeywordText1">{name}</div>
            </div>
            <div className="addKeyWordnmbrwidth">
                <div className="addKeyWordnmbrcolor">{kd}</div>
            </div>
            <div className="addKeyWordvolwidth">{vol}K Vol</div>
        </div>
        </>
    )
}

export default KeywordsListCard