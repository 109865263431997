import { Input, Modal, Typography } from "antd";
import { useState, useEffect } from "react";
import tick from "../../assets/tick.svg";
import vector from "../../assets/vector.svg";
import { Button } from "@mui/material";
import api from "../../utils/api";
import { toast } from "react-toastify";

const SaveModal = ({ open, setOpenSaveModal, docName, setDocName }) => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [buttonColor, setButtonColor] = useState('grey');
    const [selectedStatus, setSelectedStatus] = useState({});
    const [saveName, setSaveName] = useState(docName);
    const [openStatusId, setOpenStatusId] = useState(null);
    const [docStatus, setDocStatus] = useState(null);

    const statuses = [
        { label: "Done", value: "Done" },
        { label: "In Progress", value: "In Progress" },
        { label: "Waiting for Approval", value: "Waiting for Approval" },
        { label: "Published", value: "Published" },
    ];

    const toggleActive = (value) => {
        setSelectedStatus(prev => ({ ...prev, [openStatusId]: value }));
        setDocStatus(value);
        setOpenStatusId(null);
    };

    const handleStatusClick = () => {
        setOpenStatusId(true);

    };

    const handleOk = async () => {
        try {
            await api.post(
                "/save-document/",
            );
            toast.success("Document saved successfully");
            setDocName(saveName)
        } catch (err) {
            console.error("Document creation failed:", err);
            toast.error("Error creating Document. Please try again.");
        }

    };

    const handleCancel = () => {
        setOpenSaveModal(false);
        setDocStatus(null)
        setSaveName(docName)
        setOpenStatusId(false);
    };

    const handleNameChange = (e) => {
        setSaveName(e.target.value)
    };

    useEffect(() => {
        if (saveName && docStatus) {
            setIsButtonDisabled(false);
            setButtonColor('black');
        } else {
            setIsButtonDisabled(true);
            setButtonColor('grey');
        }
    }, [saveName, docStatus]);
    useEffect(() => {
        setSaveName(docName)
    }, [open]);

    return (
        <div>
            <Modal
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
                width={700}
                footer={[
                    <div className="padding-modal" key="footer">
                        <Button
                            key="submit"
                            type="primary"
                            onClick={handleOk}
                            disabled={isButtonDisabled}
                            style={{ backgroundColor: buttonColor, borderColor: buttonColor, color: 'white' }}
                        >
                            Save
                        </Button>
                    </div>,
                ]}
            >
                <div className="padding-modal">
                    <Typography.Title level={2}>Save document</Typography.Title>

                    <div>
                        <Input
                            placeholder="Document Name"
                            value={saveName}
                            onChange={handleNameChange}
                            type="text"
                            required
                            className="rename"
                        />
                    </div>

                    <div className="status-input" onClick={handleStatusClick}>
                        <p className={`sav-status-dropdown ${docStatus && docStatus.toLowerCase()}`}>
                            {docStatus || 'Status'}
                        </p>
                        <img className="status-input-img" src={vector} alt="" />
                    </div>
                    {openStatusId && (
                        <div className="save-publish">
                            {statuses.map((status) => (
                                <div
                                    key={status.value}
                                    className="save-modal-padding"
                                    onClick={() => toggleActive(status.value)}
                                >
                                    <div className={`save-modal-flex ${docStatus === status.label ? 'non-active' : 'active-status'}`}>
                                        <div>
                                            {status.label}
                                        </div>
                                        {docStatus === status.value && <img src={tick} alt="Tick" />}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default SaveModal;
