import React, { useEffect, useState } from "react";
import CardComponent from "../card/CardComponent";
import "../custom.css";
import CreateProjectModal from "./CreateProjectModal";
import useStore from "../../Store";
import api from "../../utils/api";
import Cookies from "js-cookie";
const ProjectsContent = () => {
  const { storeProjects, setDocuments} = useStore();
  const [isOpen, setIsOpen] = useState(true);
    const [allProjects, setAllProjects] = useState([]);
    const [allDocuments, setAllDocuments] = useState([]);
    const token = Cookies.get("access_token");

    useEffect(() => {
        const getDocuments = async () => {
            try {
                if (!token) {
                    throw new Error("No access token found");
                }

                const response = await api.get(
                    "/recent-documents/",
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setDocuments(response.data)
                setAllDocuments(response.data)

            } catch (error) {
                console.error("Error fetching projects:", error);
            }
        };
        getDocuments();
        setAllProjects(storeProjects);

    }, [storeProjects]);




  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
    setIsOpen(false);
  };





  return (
        <div>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              <div
                  style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "80%"
                  }}
              >
                  <div
                      style={{display: "flex", justifyContent: "space-between", padding: "20px", alignItems: "center"}}>
                      <h3 className="recent">Recent Documents</h3>
                      <button className="create_project" onClick={showModal}> Create a New Project </button>
                  </div>
                  <div className="card-container">

                      {allDocuments && allDocuments.length > 0 ? (
                          allDocuments.map((document, index) => (
                              <CardComponent
                                  projectName={document.project_name}
                                  title={document.name}
                                  seoScore={document.seo_score}
                                  status={document.doc_status}
                                  date={document.created_at}
                                  documentBrief={document.doc_brief}
                                  key={index}
                                  projectId={document.project}
                                  documentId={document.id}
                              />

                          ))
                      ) : (

                          <h1>No Document available</h1>

                      )}
                  </div>
              </div>
              <CreateProjectModal open={isModalOpen} setIsModalOpen={setIsModalOpen}
                                  setIsOpen={setIsOpen}/>
            </div>
        </div>
    );
};
        
export default ProjectsContent;