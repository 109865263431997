import React, {useState, useEffect } from "react";

import { Input, Modal, Typography } from "antd";
import api from "../../utils/api";
import { toast } from "react-toastify";
import { Button } from "@mui/material";


const DocEdit = ({ open, setIsModalOpen, id, docName, setDocName, }) => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [docNameEdit, setDocNameEdit] = useState('');
    const [buttonColor, setButtonColor] = useState('grey');
    useEffect(() => {
        setDocNameEdit(docName);
    }, [open]);

    const handleOk = async () => {
        try {

            const response = await api.patch(
                `/rename-document/${id}/`,
                { name: docNameEdit },

            );

            toast.success("Doucment Edited successfully");
            setDocName(response.data.name)
            setIsModalOpen(false);
            setIsButtonDisabled(true);
            setButtonColor('grey');
        } catch (err) {
            toast.error("Error Edited project. Please try again.", err);
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setIsButtonDisabled(true);
        setButtonColor('grey');
        setDocName(docName)
    };


    const handleNameChange = (e) => {
        const newName = e.target.value;
        setDocNameEdit(newName);
        validateForm(newName);
    };


    const validateForm = (name,) => {
        if (name) {
            setIsButtonDisabled(false);
            setButtonColor("black");
        } else {
            setIsButtonDisabled(true);
            setButtonColor("grey");
        }
    };


    return (
        <div>
            <Modal
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
                width={700}
                footer={[

                    <div className="padding-modal">
                        <Button
                            key="submit"
                            type="primary"
                            onClick={handleOk}
                            disabled={isButtonDisabled}
                            style={{ backgroundColor: buttonColor, borderColor: buttonColor, color: 'white' }}
                        >
                            Save
                        </Button>
                    </div>,
                ]}
            >

                <div className="padding-modal">
                    <Typography.Title level={2}>Rename Document</Typography.Title>

                    <div>
                        <Input
                            placeholder="Document Name"
                            value={docNameEdit}
                            onChange={handleNameChange}
                            type="text"
                            required
                            className="rename"
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
}
export default DocEdit;