import { Modal } from "antd";
import { useEffect, useState } from "react";
import "./keywords.css";
import back from "../../assets/back.svg";
import search from "../../assets/circle2017.svg";

const KeywordModal = ({ openKeword, setOpenKeyword, setDocModalOpen, keywordOptions, keyword, setKeyword, documentKeywords, setDocumentKeywords, isBuilder }) => {
    const [Modalkeywords, setModalKeywords] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedKeyword, setSelectedKeyword] = useState("");
    const [selectedDocumentKeywords, setSelectedDocumentKeywords] = useState([]);


    const backButton = () => {
        setOpenKeyword(false);
        setDocModalOpen(true);
    };

    const handleCancel = () => {
        setOpenKeyword(false);
        if (setDocModalOpen) {
            setDocModalOpen(false);
        }
    };

    useEffect(() => {
        setModalKeywords(keywordOptions);
        if (keyword) {
            setSelectedKeyword(keyword);
        } else if (documentKeywords)

            setSelectedDocumentKeywords(documentKeywords)
        else {
            setSelectedKeyword(null);
        }
    }, [openKeword, keywordOptions, keyword]);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredKeywords = Modalkeywords.filter((kw) =>
        kw.label.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleKeywordSelect = (kw) => {
        setSelectedKeyword(kw.value);
    };

    const addKeyword = () => {
        if (selectedKeyword && Modalkeywords.some(kw => kw.value === selectedKeyword)) {
            setKeyword(selectedKeyword);
            backButton();
        }
    };

    const handleDocumentKeywordToggle = (kw) => {
        debugger;
        if (selectedDocumentKeywords.includes(kw.value)) {

            setSelectedDocumentKeywords(selectedDocumentKeywords.filter((selected) => selected !== kw.value));
        } else {
            setSelectedDocumentKeywords([...selectedDocumentKeywords, kw.value]);
        }
    };


    const addDocumentKeywords = () => {
        debugger
        if (selectedDocumentKeywords.length > 0) {
            setDocumentKeywords(selectedDocumentKeywords);
        }
        setOpenKeyword(false);
    };


    const isKeywordSelected = selectedKeyword && Modalkeywords.some(kw => kw.value === selectedKeyword);

    return (
        <Modal
            open={openKeword}
            width={788}
            className={`${setDocModalOpen ? "keyword-modal" : ""}`}
            footer={null}
            onCancel={handleCancel}
        >
            <div className="Keyword-Container">
                <div className="Parent">
                    <div className="mrgn">
                        <div className="top">
                            {setDocModalOpen && <img className="img-1" src={back} alt="Back" onClick={backButton} />}
                            <div className="keyword-modal">Keyword Finder</div>
                        </div>
                        <div className="content">
                            <div className="search">
                                <input
                                    type="text"
                                    placeholder="Search Keyword"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                                <img className="img-2" src={search} alt="SearchButton" />
                            </div>
                            <div className="head-keyword">
                                <div className="h-1">Keyword</div>
                                <div className="h-2">KD</div>
                                <div className="h-3">Volume</div>
                            </div>
                            <div className="modal-body">
                                {filteredKeywords.map((kw, index) => (
                                    <div
                                        className="rows"
                                        key={index}
                                        onClick={() =>
                                            isBuilder
                                                ? handleDocumentKeywordToggle(kw)
                                                : handleKeywordSelect(kw)
                                        }
                                    >
                                        <div className="row-1">
                                            <input
                                                className="checkbox"
                                                type="checkbox"
                                                checked={
                                                    selectedDocumentKeywords.length > 0
                                                        ? selectedDocumentKeywords.includes(kw.value)
                                                        : selectedKeyword === kw.value
                                                }
                                                readOnly
                                            />
                                            <div className="text">{kw.label}</div>
                                        </div>
                                        <div className="col-2">
                                            <div className="key-nmbr">
                                                {kw.kd || '-'}</div>
                                        </div>
                                        <div className="col-3">{kw.volume || '-'}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="fotter">
                        <button
                            className={`btn-2 ${selectedDocumentKeywords.length > 0 || isKeywordSelected ? 'active' : 'disabled'}`}
                            disabled={!isKeywordSelected && selectedDocumentKeywords.length === 0}
                            onClick={() => {
                                if (isBuilder) {
                                    addDocumentKeywords();
                                } else {
                                    addKeyword();
                                }
                            }}
                        >
                            Add Keyword
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default KeywordModal;
