import React, { useEffect, useState } from "react";
import { Input, Modal, Typography } from "antd";
import { Button } from "@mui/material";
import "../Documents/createDocument.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select2 from 'react-select';
import { toast } from "react-toastify";
import api from "../../utils/api";
import KeywordModal from "./KeywordModal";
const CreateDocumentModal = ({ open, setDocModalOpen }) => {
    const { id } = useParams();

    const [docName, setDocName] = useState("newDocument");
    const [keyword, setKeyword] = useState("");
    const [documentType, setDocumentType] = useState(null);
    const [tone, setTone] = useState(null);
    const [language, setLanguage] = useState("english");
    const [documentBrief, setDocumentBrief] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [buttonColor, setButtonColor] = useState('grey');
    const [openKeword, setOpenKeyword] = useState(false)

    const [keywordsOptions, setKeywordsOptions] = useState([]);
    const [languageOptions, setLanguageOptions] = useState([])
    const [docTypeOptions, setDocTypeOptions] = useState([])
    const [toneOptions, setToneOptions] = useState([])


    const { Text } = Typography;
    const navigate = useNavigate();


    const mapToneOptions = (tones) => {
        return tones.map(tone => ({
            value: tone.tone_value.toLowerCase(),
            label: tone.tone_value,
        }));
    };

    const mapKeywordsOptions = (keywords) => {
        return keywords.map(keyword => ({
            value: keyword.keyword.toLowerCase(),
            label: keyword.keyword,
        }));
    };

    const mapLanguageOptions = (languages) => {
        return languages.map(language => ({
            value: language.language_name.toLowerCase(),
            label: language.language_name,
        }));
    };

    const mapDocTypeOptions = (docTypes) => {
        return docTypes.map(docType => ({
            value: docType.type.toLowerCase(),
            label: docType.type,
        }));
    };



    const keywords = [
        {
            "id": 3,
            "word": "API"
        },
        {
            "id": 2,
            "word": "Django"
        },
        {
            "id": 5,
            "word": "hello"
        },
        {
            "id": 6,
            "word": "helloo"
        },
        {
            "id": 4,
            "word": "Serialization"
        },
        {
            "id": 1,
            "word": "string"
        }
    ]

    useEffect(() => {

        const getData = async () => {

            try {
                const response = await api.get(
                    `/project-data/${id}`,

                );
                setToneOptions(mapToneOptions(response.data.tones));
                setKeywordsOptions(mapKeywordsOptions(response.data.keywords));
                setLanguageOptions(mapLanguageOptions(response.data.languages));
                setDocTypeOptions(mapDocTypeOptions(response.data.document_types));
                setDocName('newDocument')

            } catch (err) {
                console.error("fetching data failed:", err);
            }
        }
        getData();
    }, []);


    const handleOpenKeyword = () => {
        setOpenKeyword(true)
        setDocModalOpen(false)
    }
    const handleOk = async () => {
        try {
            const response = await api.post(
                "/create-document/",
                { project: id, name: docName, main_keyword: keyword, doc_type: documentType.value, tone: tone.value, language: language.value, doc_brief: documentBrief },


            );
            console.log("Document created successfully:", response.data);
            toast.success("Document created successfully");
            handleCancel()
            navigate(`/document/${response.data.document.id}/`);


        } catch (err) {
            console.error("Document creation failed:", err);
            toast.error("Error creating Document. Please try again.");
        }
    };

    const handleCancel = () => {
        setDocModalOpen(false);
        setDocName('')
        setDocumentBrief('')
        setDocumentType('')
        setTone('')
        setLanguage('')
        setKeyword("")

    };

    const handleSelectChange = (e) => {
        setKeyword(e.target.value);
    };

    useEffect(() => {
        if (keyword && documentType && tone && language) {
            setIsButtonDisabled(false);
            setButtonColor('black');
        } else {
            setIsButtonDisabled(true);
            setButtonColor('grey');
        }
    }, [keyword, documentType, tone, language, documentBrief]);
    return (
        <div>
            <Modal
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
                width={788}
                footer={null}
                className="modal-padding"
            >
                <div className="modal-content">
                    <Typography.Title level={2} className="heading">Create a new document</Typography.Title>
                    <p className="p-text">The keyword you choose defines the main topic of the content you are going to create and the SERP will be based on it.</p>
                    <Text strong>*Keyword</Text>
                    <div>
                        <Input
                            onChange={handleSelectChange}
                            placeholder="Your keyword here..."
                            className="keyword"
                            value={keyword}


                        />
                    </div>
                    <p>Need some help? <Link to="" className="link_tag" onClick={handleOpenKeyword}>CHECK OUT OUR KEYWORD FINDER.</Link></p>
                    <div className="select_div">
                        <Select2
                            value={language}
                            onChange={setLanguage}
                            placeholder="Select Language"
                            options={languageOptions}
                        />
                        <Select2
                            value={documentType}
                            placeholder="Select Document Type"
                            onChange={setDocumentType}
                            options={docTypeOptions}
                        />
                        <Select2
                            value={tone}
                            placeholder="Select Tone"
                            onChange={setTone}
                            options={toneOptions}
                        />
                    </div>
                    <div>
                        <Input.TextArea
                            placeholder="Document Brief"
                            rows={4}
                            required
                            value={documentBrief}
                            onChange={(e) => setDocumentBrief(e.target.value)}
                            className="textarea_class"
                        />
                    </div>
                </div>

                <div className="footer">
                    <Button
                        key="submit"
                        type="primary"
                        onClick={handleOk}
                        disabled={isButtonDisabled}
                        style={{ backgroundColor: buttonColor, borderColor: buttonColor, color: 'white' }}
                    >
                        Create
                    </Button>
                </div>
            </Modal>

            <KeywordModal openKeword={openKeword} setOpenKeyword={setOpenKeyword} setDocModalOpen={setDocModalOpen}
                keywordOptions={keywordsOptions} keyword={keyword} setKeyword={setKeyword} />
        </div>
    );
};

export default CreateDocumentModal;
