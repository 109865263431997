import React from "react";
import "./CardStyles.css"; // Make sure to save the above CSS in this file
import { Link } from "react-router-dom";

const Card = ({ projectId, projectName, title, seoScore, status, date, documentBrief, noCard, documentId }) => {
    return (
        <div className="card">
            <div className="card-header">
                {projectName && <h6 className="project_name"> <Link to={`/project/${projectId}/`} > {projectName} </Link></h6>}
                {title && <h3><Link to={`/document/${documentId}`} > {title} </Link></h3>}
                {documentBrief ? (
                    <p className="para">{documentBrief}</p>
                ) : (
                    <p className="para">No description available</p>
                )}
                <p className="seo_p">SEO Score:</p>
                {seoScore ? (
                    <>

                        <h2 className="score2">{seoScore}%</h2>
                    </>
                ) : (
                    <h2 className="score2" > - %</h2>
                )}
            </div>
            <div className="card-body">
                <div className="display_head">
                    {status && (
                        <span className={`status ${status.toLowerCase().replace(" ", "-")}`}>
                            {status}
                        </span>
                    )}
                    {date && <p className="date_">{date}</p>}
                </div>
            </div>
            <div className="card-footer"></div>
        </div>
    );
};

export default Card;