
import vector from ".././assets/vector.svg";
import tickIcon from ".././assets/tick.svg";
import { useEffect, useState } from "react";
import api from "../utils/api";
const TargetAudience = () => {

    const [open, setOpen] = useState(false);
    const [selectedAudience, setSelectedAudience] = useState("Target audience");


    const [audiences, setAudiences] = useState([]);


    useEffect(() => {
        const getTargetAudience = async () => {
            try {

                const response = await api.get(
                    "/target-audience/",

                );

                setAudiences(response.data)
                console.log("Project Edited successfully");



            } catch (err) {
                console.error("Error Edited project. Please try again.");
            }
        };


        getTargetAudience();
    }, []);


    const toggleActive = (id) => {
        setAudiences((prevAudiences) =>
            prevAudiences.map((audience) => {
                if (audience.id === id) {
                    setSelectedAudience(audience.title); // Set selected audience
                    return { ...audience, isActive: true };
                }
                return { ...audience, isActive: false };
            })
        );
        setOpen(false)

    };

    const handleClick = () => {
        if (open) {
            setOpen(false)
        } else {
            setOpen(true)
        }
    };

    return (
        <>
            <div className="div-2" onClick={handleClick} >
                <strong>{selectedAudience}</strong>
                <img className="div-2-img-1" src={vector} alt="" />


                {open &&
                    <div className="dropdown">
                        <div className="dropdown-div1">
                            <div className="dropdown-h1">Target audience</div>
                            <div className="dropdown-p1">
                                Defining the correct target audience enhances AI's ability to <br />
                                generate superior content.
                            </div>
                        </div>

                        {audiences.map(({ id, title, description, age_group, isActive }) => (
                            <div key={id} className={isActive ? 'Active-div' : 'dropdown-div1'} onClick={() => toggleActive(id)}>
                                <div className="active-flex">
                                    <div className="dropdown-h2">{title}</div>
                                    {isActive && <img src={tickIcon} alt="Tick" />}
                                </div>
                                <div className="dropdown-p2">
                                    {age_group}<br />
                                    {description}
                                </div>
                            </div>
                        ))}
                    </div>
                }
            </div>
        </>
    );
}
export default TargetAudience;