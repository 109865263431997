import { Modal } from "antd"

const SerpModal = ({ open, serpList, setOpenSerpModal }) => {
    const handleCancel = () => {
        setOpenSerpModal(false)
    }

    return (
        <Modal
            open={open}
            width={917}
            footer={null}
            onCancel={handleCancel}
        >
            <div className="serpTableContainer">
                <div className="serpTableParent">
                    <div className="serpHeading">
                        SERP
                    </div>
                    <div className="serpTableTopWidth">
                        <div className="serpTableHidingFlex">
                            <div className="serph1">
                                Word no.
                            </div>
                            <div className="serph2">
                                Outbound links
                            </div>
                            <div className="serph3">
                                Semantic keyword
                            </div>
                            <div className="serph4">
                                Title score
                            </div>
                            <div className="serph5">
                                SEO score
                            </div>
                        </div>
                    </div>

                    {serpList.map((item, index) => (
                        <div className="serpLine1Flex" key={item.id || index}>
                            <div className="serpLine1ParagraphFlex">
                                <div className="serpLineNmbr">
                                    {index + 1} {/* Adjusted to show the correct number */}
                                </div>
                                <div className="serpParagrph">
                                    {item.label}
                                    <div className="serpPargrphLink">
                                        {item.url}
                                    </div>
                                </div>
                            </div>
                            <div className="serpTablenmbrsFlex">
                                <div className="wordNo">
                                    1,566
                                </div>
                                <div className="outboundLinks">
                                    6
                                </div>
                                <div className="semanticKeywords">
                                    12
                                </div>
                                <div className="titleScore">
                                    67
                                </div>
                                <div className="seoScore">
                                    82
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="serpTableFooter">
                    <div className="serpFooterImgs">
                        <div className="serpFooterLeftImg">
                            {/* <img src="IMAGES/Chevron_Left_Light.svg" alt="" /> */}
                        </div>
                        <div className="serpFooterRightImg">
                            {/* <img src="IMAGES/Chevron_Right.svg" alt="" /> */}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default SerpModal;
